<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import AgentAutoComplete from '@/components/agent-auto-complete-input'

import { getPMApi } from '@/api/pm'


//import Choices from "choices.js";

/**
 * User list component
 */
export default {
  page: {
    title: "RTA List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      pmList: [],
      title: "Agent PM Trasnfer Out",
      items: [
        {
          text: "PM",
          href: "/",
        },
        {
          text: "Agent PM Trasnfer Out",
          active: true,
        },
      ],

      totalRows: 0,
      currentPage: 1,
      perPage: 500,
      agent: {
        agent_name: ''
      },
      brokerage_name : '',
      brokerage_addr : '',
      total_amount: 0,
      pageOptions: [10, 25, 50, 100],
      filter: '',
      filterOn: ["pm_id", "address"],
      sortBy: "value",
      sortDesc: false,
      start_page: 1,
      fields: [
        {
          key: "PMACode",
          sortable: true,
        },

        {
          key: "Address",
          sortable: true,
        },

        { key: 'LandLord', label: 'Owner' },
        {
          key: "Balance",
          sortable: false,
        },

      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    AgentAutoComplete

  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {

  },
  mounted() {


  },
  methods: {

    onSelectedAgent(ag) {

      this.agent = ag.agent
      this.agent.agent_name = ag.label
      this.queryAgentPMList()
    },

    queryAgentPMList() {
      let data = {
        agent_id: this.agent.id
      }

      this.total_amount = 0
      this.pmList = []
      getPMApi().agent_all_rta_list(data).then((res) => {
        res.data.map((o) => {
          o.pm_id = o.property_id
          this.total_amount += o.balance_in_trust
          this.pmList.push(o)
        })
        this.totalRows = res.data.length;
      })


    },

    onTermination() {
      let data = {
        agent_id: this.agent.id,
        release_to      : this.brokerage_name,
        release_to_addr : this.brokerage_addr,
        addr_post_code  : ''
      }
      getPMApi().terminate_agent_all_rtas(data).then(res =>{
        console.log(res)
      }) 
    }





  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />




    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div class="mb-3">
                  <h5 class="card-title">
                    RTA List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->
              <div class="col-md-4">
                <AgentAutoComplete v-model="agent.agent_name" @onSelected="onSelectedAgent" autocomplete="off"
                  :init_data="{ agent_name: agent.agent_name, placeholder: ' Search Agent ' }" />
              </div>

              <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                  <b-button variant="danger" @click="onTermination">Transfer Out</b-button>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="card">
              <div class="card-body">

                <div class="invoice-title">
                  <div class="text-muted">
                    <h4 class="float-end font-size-15">
                    </h4>
                    <div class="row">

                      <div class="col-md-2">
                        <p class="mb-3">Out Brokerage Name:</p><input class="form-control"
                          placeholder="enter brokerage name" v-model="brokerage_name" />
                      </div>
                      <div class="col-md-6">
                        <p class="mb-3">Out Brokerage Address:</p><input class="form-control"
                          placeholder="enter brokerage address" v-model="brokerage_addr"/>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
              <!-- card body-->
            </div>
            <!-- end card -->

            <div class="py-4 mt-3">

            </div>
            <div class="table-responsive mt-3">
              <div class="table align-middle table-nowrap">
                <b-table ref="list_tbl_ref" :items="pmList" :fields="fields" responsive="sm" :per-page="totalRows"  foot-clone
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" class="table-check">

                  <template #cell(PMACode)="data">
                    <router-link :to="{ name: 'pm-overview', query: { pm_id: `${data.item.pm_id}` } }"
                      class="text-body fw-medium">{{ data.item.o_code }}</router-link>
                  </template>
                  <template #cell(Address)="data">
                    {{ data.item.address }}
                  </template>
                  <template #cell(LandLord)="data">
                    <p v-for="(ll, idx) in data.item.landlords" :key="ll.id + '_' + idx">
                      {{ ll.name }}
                    </p>
                  </template>

                  <template #cell(Balance)="data">
                    <span sm="3" class="text-sm-center text-center"> {{ data.item.balance_in_trust.toLocaleString() }}</span>
                  </template>

                  <template #foot(Balance)>
                    {{ '' + total_amount.toLocaleString() }}
                  </template>

                  <template #foot(Address)>
                    {{ '' }}
                  </template>
                  <template #foot(PMACode)>
                    {{ '' }}
                  </template>
                  <template #foot(LandLord)>
                    {{ 'Total: $' }}
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->



          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
  </div>
  <!-- end row -->
</Layout></template>
